import { useEffect, useState } from "react";
import '../../css/pages/Events.css'
import EventCard from "../components/EventCard";

const Events = () => {

  // const test = [
  //   {
  //     "name": "XGC At Home 7",
  //     "game": "SFV",
  //     "date": "May 19th",
  //     "time": "19:30",
  //     "where": "IFGC Discord",
  //     "signup": "none",
  //     "stream": "none",
  //     "details": "SFV Online Casuals! Winner Stays on, Max 4 Person Lobbies, FT2. See you then! 😄",
  //     "imgUrl": "https://cdn.discordapp.com/attachments/778019953160224768/843912560831102996/XGC_at_HOME_7.png"
  //   },
  //   {
  //     "name": "Strive 4 Glory",
  //     "game": "Guilty Gear Strive",
  //     "date": "June 13th",
  //     "time": "3pm GMT",
  //     "where": "discord.gg/celticthrowdown",
  //     "signup": "smash.gg/s4g",
  //     "stream": "twitch.tv/asonelive",
  //     "details": "Our first real look at Guilty Gear Strive online. Free to enter tournament with live stream.",
  //     "imgUrl": "https://cdn.discordapp.com/attachments/778019953160224768/843488441957613578/Strive4Glory.png"
  //   },
  //   {
  //     "name": "Strive 4 Glory",
  //     "game": "Guilty Gear Strive",
  //     "date": "June 13th",
  //     "time": "3pm GMT",
  //     "where": "https://discord.gg/celticthrowdown",
  //     "signup": "@ the venue",
  //     "stream": "https://twitch.tv/asonelive",
  //     "details": "Our first real look at Guilty Gear Strive online. Free to enter tournament with live stream.",
  //     "imgUrl": "https://cdn.discordapp.com/attachments/778019953160224768/843488441957613578/Strive4Glory.png"
  //   }
  // ]

  const [events, setEvents] = useState([])

  useEffect(() => {
    const fetchEvents = async () => {
      const eventsResponse = await fetch(`https://fgcireland.com/ifgcbot/src/events.json`)
      const EVENTS_JSON = await eventsResponse.json();

      setEvents(EVENTS_JSON);
    }

    fetchEvents();
  }, [])
  
  return (
    <article className="events">
      {events.length > 0
        ? events.reverse().map(eventEntry =>
          <EventCard
            name={eventEntry.name}
            game={eventEntry.game}
            date={eventEntry.date}
            time={eventEntry.time}
            where={eventEntry.where}
            signup={eventEntry.signup}
            stream={eventEntry.stream}
            details={eventEntry.details}
            imgUrl={eventEntry.imgUrl}
            key={`${eventEntry.name}-event-card`}
        />)
        : <div className="noEventsContainer">
            <h1>Sorry, there's no events coming up!</h1>
            <h1>Check back again tomorrow :D</h1>
          </div>
        
      }
      
    </article>
  )
}
  

export default Events;