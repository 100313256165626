import './App.css';
import {
	HashRouter as Router,
	Routes,
	Route,
	Navigate,
} from "react-router-dom";
import Logo from './js/components/Logo';
import NavBar from './js/components/NavBar';


import Discords from './js/pages/Discords';
import Regions from './js/pages/Regions';
import Socials from './js/pages/Socials';
import Events from './js/pages/Events';

const App = () => {
	return (
		<div className="App">
      <Logo></Logo>
			<Router>
        <NavBar></NavBar>
				
				<Routes>
          <Route path={`/discords`} element={<Discords />} />
					<Route path={`/regions`} element={<Regions />} />
					<Route path={`/events`} element={<Events />} />
					<Route path={`/socials`} element={<Socials />} />
					<Route path="*" element={<Navigate to="/discords" replace />} />
				</Routes>

			</Router>        
		</div>
	)
};

export default App;