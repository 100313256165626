import DiscordCard from "../components/DiscordCard";
import '../../css/pages/Discords.css'
import DISCORDS from '../constants/Discords'

const Discords = () => 
  <article className="discords">
    {Object.keys(DISCORDS).map(category =>
      <section key={`discord-category-${category}`}>
        <h1>{category}</h1>
        <div className="cardContainer">
          {Object.keys(DISCORDS[category]).map(discordEntry =>
            <DiscordCard
              name={DISCORDS[category][discordEntry].name}
              desc={DISCORDS[category][discordEntry].desc}
              link={DISCORDS[category][discordEntry].link}
              linkText={DISCORDS[category][discordEntry].linkText}
              key={`discord-card-${category}-${DISCORDS[category][discordEntry].name}`}
            />
          )}
        </div>
      </section>
    )}
  </article>


export default Discords;