const DISCORDS = {
  Games: {
    IFGC: {
      name: "IFGC",
      desc: "The main hub of all things IFGC, if you're not sure where to join first, this is the place!",
      link: "https://discord.gg/0k8YDBGVSKMuS1PM",
    },
    EireDashers: {
      name: "Eiredashers",
      desc: "The Irish discord for all airdashers and anime games from Blazblue to Guilty Gear",
      link: "https://discord.gg/Hnudpvkqne",
    },
    SmashIreland: {
      name: "Smash Ireland",
      desc: "The centre of all things Smash related in Ireland, from Melee to Ultimate",
      link: "https://discord.gg/mmA8ezvFd7",
    },
    TekkenIreland: {
      name: "Tekken Ireland",
      desc: "One of Ireland's strongest games thanks to a dedicated grassroots effort",
      link: "https://discord.gg/Jr6vVHb",
    },
    DBFZIreland: {
      name: "DBFZ Ireland",
      desc: "A growing group of players dedicated to DBFZ, running regular online events",
      link: "https://discord.gg/KhMGYjEEZH",
    },
    IrishMK: {
      name: "Irish MK",
      desc: "Love Mortal Kombat? We've got an insanely strong scene!",
      link: "https://discord.gg/eRs2rnXEbZ",
    },
    SSBMIreland: {
      name: "SSBM Ireland",
      desc: "Interested in playing Melee in Ireland? This is the place to be!",
      link: "https://discord.gg/x5mj5pFs",
    },
    StreetFighterIreland: {
      name: "SF Ireland",
      desc: "Ireland's dedicated SF server, for discussion of SF 2 to 6 and everything inbetween",
      link: "https://discord.gg/ZFkvm2qHmf",
    },
    VirtuaFighterIreland: {
      name: "Virtua Fighter Ireland",
      desc: "Discuss VF5's remake and the upcoming VF6 with Ireland's best!",
      link: "https://discord.gg/vXV94JaaGA",
    },

  },
  Regional: {
    Cork: {
      name: "Leeside Collision",
      desc: "Cork's dedicated discord for its local FGC. They play a variety of games!",
      link: "https://discord.gg/D7uK2xvmqW",
    },
    Dublin: {
      name: "Dublin FGC",
      desc: "There isn't really a Dublin specific discord so most Dublin players use the IFGC one",
      link: "https://discord.gg/0k8YDBGVSKMuS1PM",
    },
    TNA: {
      name: "The North Awakens",
      desc: "The main discord of Smash in N.Ireland! Join for info on all events happening in the North!",
      link: "https://discord.gg/ADp6T2MsKS",
    },
    // SmashKerry: {
    //   name: "Smash Kerry",
    //   desc: "The dedicated Discord server for the Smash scene in Kerry!",
    //   link: "https://discord.gg/UA73qrm",
    // },
    DAC: {
      name: "DAC",
      desc: "An active Dublin Smash scene running regular events",
      link: "https://discord.gg/2FSxmYjnjm",
    },
    'Rebel\'s Guard': {
      name: "Rebel's Guard",
      desc: "The Munster Smash scene, events running mostly in Cork & Kerry",
      link: "https://discord.gg/QCF4KthW8W",
    },
    ButtonMash: {
      name: "Button Mash",
      desc: "Primarily running FGC events in Belfast often alongside Big Fish (Smash events)",
      link: "https://discord.gg/7GnJHuKAnG",
    },
    Level1: {
      name: "Level 1",
      desc: "Running a yearly national tournament in Dublin, featuring games from all communities",
      link: "https://discord.gg/mQ4CUQmyTP",
    },
  }
}

export default DISCORDS;