import { Link, useLocation } from "react-router-dom";


const NavOption = ({name, link}) => {

  let location = useLocation();

  return(
    <Link to={link} style={!location.pathname.endsWith(link) ? {textDecoration: "none"} : {color: "#7ce96c"}}>{name}</Link>
  )
}


export default NavOption;