import NavOption from "./NavOption";
import '../../css/components/NavBar.css'

const NAV_OPTIONS = [
  {name: "Discords", link: "discords"},
  {name: "Regions", link: "regions"},
  {name: "Events", link: "events"},
  {name: "Socials", link: "socials"},

]

const NavBar = () => 
  <div className="NavBar">
    {NAV_OPTIONS.map(navEntry =>
      <NavOption 
        name={navEntry.name}
        link={navEntry.link}
        key={`${navEntry.name}-link`}
      />  
    )}
  </div>

export default NavBar;